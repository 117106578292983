import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGifts } from '@fortawesome/free-solid-svg-icons'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import BackToTopButton from '../../components/BackToTopButton'
import Link, { navigate } from '../../components/LinkWrapper'
import AcademyExtras from '../../components/AcademyExtras'
import ContactForm from '../../components/ContactForm'
import ScrollOnAnimation from '../../components/ScrollOnAnimation'
import { coursesImagesMapping } from '../../utils'
import i18n from '../../locales/he.yaml'

const CoursesTemplate = ({ pathname, images, product }) => {
  const [filteredCategory, setFilteredCategory] = useState('all')
  const [filteredLevel, setFilteredLevel] = useState('all')
  const [isInLaunch, setIsInLaunch] = useState(false)

  return (
    <>
      <BackToTopButton selectorId='academy-courses' />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/academy/'}>{i18n.header.academy}</Link>
                </li>
                <li className='is-active'>
                  <Link to={pathname} aria-current='page'>
                    {i18n.header.courses}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column'>
                <h2 className='is-size-5 has-text-weight-normal has-text-nude'>
                  {i18n.courses.subtitle}
                </h2>
                <h1 className='is-size-4'>{i18n.courses.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollOnAnimation>
        <div className='hero has-background-nude-light'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <div className='columns is-centered'>
                <div className='column is-6 is-full-touch'>
                  <FontAwesomeIcon
                    icon={faGifts}
                    className='has-text-nude is-margin-bottom-4'
                    size='2x'
                  />
                  <div className='is-margin-bottom-4'>
                    <h1 className='has-text-nude is-size-4 white-space-normal-mobile is-margin-top-0'>
                      {i18n.securityForcesBenefits.subtitle}
                    </h1>
                  </div>
                  <p className='is-margin-bottom-6'>
                    {i18n.securityForcesBenefits.preview}
                  </p>
                  <p>
                    <Link
                      to='/academy/security-forces-benefits/'
                      className='button is-white'
                    >
                      {i18n.securityForcesBenefits.previewCTA}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <div className='hero'>
        <div className='hero-body is-padding-bottom-0'>
          <div className='container content'>
            <p className='is-margin-bottom-0'>{i18n.courses.description}</p>
            <div className='accordion is-fixed-on-open is-padding-top-6'>
              <input type='checkbox' id='categories-levels' hidden />
              <label className='accordion-header' htmlFor='categories-levels'>
                <a className='button is-text is-padding-left-0'>
                  {i18n.courses.expand}
                </a>
              </label>
              <div className='accordion-body'>
                <div className='columns is-margin-top-2'>
                  <div className='column'>
                    <div className='extras-item'>
                      <div className='has-text-centered'>
                        <h5>{i18n.courses.categoriesLabel}</h5>
                      </div>
                      {i18n.courses.categories.map((category) => (
                        <p key={category.title}>
                          <span className='has-text-weight-bold'>
                            {category.title}
                          </span>
                          {' - '}
                          <span>{category.description}</span>
                        </p>
                      ))}
                    </div>
                  </div>
                  <div className='column'>
                    <div className='extras-item'>
                      <div className='has-text-centered'>
                        <h5>{i18n.courses.levelsLabel}</h5>
                      </div>
                      {i18n.courses.levels.map((level) => (
                        <p key={level.title}>
                          <span
                            className={clsx(
                              'course-level is-margin-left-3 is-margin-right-4',
                              `level-${level.title}`
                            )}
                          />
                          <span>{level.description}</span>
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollOnAnimation>
        <div className='hero'>
          <div className='hero-body is-padding-bottom-0'>
            <div className='container content'>
              <div className='columns'>
                <div className='column is-narrow'>
                  <label className='label is-inline-block'>
                    {i18n.courses.filterBy}
                  </label>
                </div>
                <div className='column is-narrow'>
                  <label className='label is-margin-right-3 is-inline-block'>
                    {i18n.courses.category}
                  </label>
                  <div className='control is-inline-block'>
                    <label className='radio'>
                      <input
                        type='radio'
                        name='category'
                        value='all'
                        checked={filteredCategory === 'all'}
                        onChange={(e) => setFilteredCategory(e.target.value)}
                      />{' '}
                      {i18n.courses.all}{' '}
                    </label>
                    {i18n.courses.categories.map((category) => (
                      <label className='radio' key={category.title}>
                        <input
                          type='radio'
                          name='category'
                          value={category.title}
                          checked={filteredCategory === category.title}
                          onChange={(e) => setFilteredCategory(e.target.value)}
                        />{' '}
                        {category.title}{' '}
                      </label>
                    ))}
                  </div>
                </div>
                <div className='column is-narrow'>
                  <label className='label is-margin-right-3 is-inline-block'>
                    {i18n.courses.level}
                  </label>
                  <div className='control is-inline-block'>
                    <label className='radio'>
                      <input
                        type='radio'
                        name='level'
                        value='all'
                        checked={filteredLevel === 'all'}
                        onChange={(e) => setFilteredLevel(e.target.value)}
                      />{' '}
                      {i18n.courses.all}{' '}
                    </label>
                    {i18n.courses.levels.map((level) => (
                      <label className='radio' key={level.title}>
                        <input
                          type='radio'
                          name='level'
                          value={level.title.toString()}
                          checked={filteredLevel === level.title.toString()}
                          onChange={(e) => setFilteredLevel(e.target.value)}
                        />{' '}
                        {level.title}{' '}
                      </label>
                    ))}
                  </div>
                </div>
              </div>
              <div className='columns'>
                <div className='column is-narrow'>
                  <label className='checkbox'>
                    <input
                      type='checkbox'
                      value={isInLaunch}
                      onChange={() => setIsInLaunch(!isInLaunch)}
                      className='is-margin-right-3'
                    />
                    {i18n.courses.launch}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <div id='academy-courses' className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-multiline'>
              {i18n.courses.courses
                .filter(
                  (course) =>
                    (filteredCategory === 'all'
                      ? !!course.category
                      : course.category === filteredCategory) &&
                    (filteredLevel === 'all'
                      ? !!course.level
                      : course.level.toString() === filteredLevel) &&
                    (isInLaunch ? course.isInLaunch : true)
                )
                .map((course, index) => (
                  <ScrollOnAnimation key={index}>
                    <div className='column is-3-widescreen is-4-desktop is-6-tablet is-10-mobile is-offset-1-mobile'>
                      <div
                        className='card is-clickable'
                        style={{ height: '100%' }}
                        onClick={() =>
                          navigate(
                            `${pathname}${course.name.replaceAll(' ', '-')}/`
                          )
                        }
                      >
                        {course.isInLaunch && (
                          <div className='course-progress-tag is-margin-top-4'>
                            <span className='tag is-nude is-light'>
                              {course.isPreOrder
                                ? i18n.courses.preOrderLabel
                                : i18n.courses.launchLabel}{' '}
                              {course.launchEndDate
                                ? `${i18n.courses.launchUntil} ${course.launchEndDate}`
                                : ''}
                            </span>
                          </div>
                        )}
                        <div className='card-image'>
                          <GatsbyImage
                            image={getImage(images[course.name])}
                            title={course.image.title}
                            alt={course.image.alt}
                            style={{
                              maxWidth: getImage(images[course.name]).width,
                              margin: '0 auto' // used to center the image
                            }}
                          />
                        </div>
                        <div className='level is-mobile p-2 is-margin-bottom-0'>
                          <div className='level-left'>
                            <div className='level-item'>
                              <div className='tags has-addons'>
                                <span className='tag'>
                                  {i18n.courses.category}
                                </span>
                                <span className='tag is-nude-light'>
                                  {course.category}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='level-right'>
                            <div className='level-item'>
                              <div className='tags has-addons'>
                                <span className='tag'>
                                  {i18n.courses.level}
                                </span>
                                <span className='tag'>
                                  <span
                                    className={clsx(
                                      'course-level',
                                      `level-${course.level}`
                                    )}
                                    style={{ top: '0.15rem' }}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='card-footer is-flex-grow-1'>
                          <Link
                            className='card-footer-item has-text-centered'
                            to={`${pathname}${course.name.replaceAll(
                              ' ',
                              '-'
                            )}/`}
                            onClick={(e) => e.stopPropagation()}
                          >
                            {course.category === 'דמויות'
                              ? i18n.courses.figureMoreDetailsPrefix
                              : i18n.courses.moreDetailsPrefix}{' '}
                            {course.name}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </ScrollOnAnimation>
                ))}
            </div>
          </div>
        </div>
      </div>
      <AcademyExtras product={product} includeCta={false} />
      <div id='registration' className='hero has-background-nude-light'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-centered'>
              <div className='column is-narrow'>
                <ContactForm product={product} name='courses' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Courses = ({ location: { pathname } }) => {
  const product = {
    category: i18n.academy.metadata.title,
    name: i18n.courses.metadata.title
  }
  const images = useStaticQuery(CoursesQuery)
  return (
    <Layout product={product}>
      <Seo
        metadata={i18n.courses.metadata}
        pathname={pathname}
        og={{
          image: getSrc(images.coursesHero),
          alt: i18n.courses.mainImage.alt
        }}
        schemaMarkup={{
          breadcrumbs: [
            { name: i18n.header.academy, path: '/academy/' },
            { name: i18n.header.courses, path: '/academy/courses/' }
          ]
        }}
      />
      <CoursesTemplate
        pathname={pathname}
        images={coursesImagesMapping(images)}
        product={product}
      />
    </Layout>
  )
}

export default Courses

const CoursesQuery = graphql`
  query CoursesQuery {
    coursesHero: file(relativePath: { eq: "logo-academy-circle.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    bear: file(
      relativePath: {
        eq: "עוגה-מעוצבת-עם-פיסול-דובי-בחצי-תלת-מימד-בבצק-סוכר-ובלונים-מג׳לטין.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    rabbit: file(
      relativePath: { eq: "עוגה-מעוצבת-עם-פיסול-ארנב-בתלת-מימד-בבצק-סוכר.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    candies: file(
      relativePath: {
        eq: "עוגת-ממתקים-מעוצבת-בבצק-סוכר-עם-בובה-וחתול-מפוסלים-בעבודת-יד.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tallButterflies: file(
      relativePath: {
        eq: "עוגה-מעוצבת-סופר-גבוהה-מצופה-בטקסטורת-שוקולד-עם-פסלים-של-טירה-מפוסלים-מבצק-סוכר-ופרפרים-מנייר-אורז.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    airplanes: file(
      relativePath: {
        eq: "עוגה-מעוצבת-בסגנון-וינטג׳-עם-ציור-ופיסול-פנים-של-ילד-מפוסל-בצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    giftBox: file(
      relativePath: { eq: "עוגה-מעוצבת-בבצק-סוכר-בצורה-של-קופסת-מתנה.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tiersCastle: file(
      relativePath: {
        eq: "עוגת-2-קומות-מעוצבת-בצורת-טירה-מפוסלת-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tallSafari: file(
      relativePath: {
        eq: "עוגה-מעוצבת-סופר-גבוהה-מקושטת-בדפי-אורז-ועם-חיות-הספארי-אריה-ג׳ירפה-קוף-פיל-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tallCircus: file(
      relativePath: {
        eq: "עוגה-מעוצבת-סופר-גבוהה-מקושטת-בדפי-אורז-ועם-חיות-הקרקס-קוף-פיל-נמר-כלב-ים-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    princess: file(
      relativePath: { eq: "נסיכה-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    ballerina: file(
      relativePath: { eq: "בלרינה-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    llama: file(
      relativePath: {
        eq: "לאמה-עומדת-בעלת-מבנה-של-4-רגליים-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    safariAnimalsPro: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-חיות-הספארי-אריה-ג׳ירפה-קוף-פיל-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    circusAnimalsPro: file(
      relativePath: {
        eq: "עוגה-מעוצבת-סופר-גבוהה-מקושטת-בדפי-אורז-ועם-חיות-הקרקס-קוף-פיל-נמר-כלב-ים-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    safariAnimalsMasters: file(
      relativePath: {
        eq: "חיות-הספארי-אריה-ג׳ירפה-זברה-היפופוטם-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tractor: file(
      relativePath: {
        eq: "עוגת-טרקטור-תלת-מימדי-מפוסל-בבצק-סוכר-עם-שלט-מזל-טוב.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    jeepSafariAnimals: file(
      relativePath: {
        eq: "עוגת-ג׳יפ-של-חיות-הספארי-בתלת-מימד-אריה-ג׳ירפה-זברה-היפופוטם-מפוסלים-בבצק-סוכר-ועצים-מנייר-אורז.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tallPatches: file(
      relativePath: {
        eq: "עוגה-מעוצבת-סופר-גבוהה-מקושטת-בטלאים-ושמיכה-ממרשמלו-ועליה-בובות-ענקיות-של-כלב-עשוי-מכדורי-שוקולד-וארנב-מפוסל-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    volcano: file(
      relativePath: {
        eq: "עוגת-הר-געש-תלת-מימד-עם-עשן-ועננים-ועליה-דינוזאורים-טי-רקס-טריצרטופס-דיפלודוקוס-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    dinosaurs: file(relativePath: { eq: "דינוזאורים-מפוסלים-בבצק-סוכר.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    calimero: file(
      relativePath: {
        eq: "עוגות-עם-אפרוחים-קלימרו-ופריסילה-מעוצבים-בבצק-סוכר.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    cups: file(
      relativePath: {
        eq: "עוגת-ממתקים-ומפלצות-מפוסלות-בבצק-סוכר-על-ערימת-ספלים.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    bambi: file(
      relativePath: {
        eq: "במבי-עומד-בעל-מבנה-של-4-רגליים-מפוסל-בבצק-סוכר-בעבודת-יד.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    horse: file(relativePath: { eq: "סוס-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    surfer: file(
      relativePath: { eq: "גולש-הומוריסטי-על-גלשן-מפוסל-בבצק-סוכר.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    farmer: file(relativePath: { eq: "חקלאי-הומוריסטי-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    waiter: file(relativePath: { eq: "מלצר-הומוריסטי-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    flamingo: file(
      relativePath: { eq: "עוגת-קומות-של-פלמינגו-מעוצבת-בבצק-סוכר.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    flamingo2: file(relativePath: { eq: "פלמינגו-גדול-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    dinosaur: file(relativePath: { eq: "דינוזאורית-מפוסלת-בבצק-סוכר.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    heroes: file(
      relativePath: {
        eq: "עוגת-גיבורי-על-תלת-מימד-עם-קומות-באוויר-מפוסלת-בבצק-סוכר.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    indianCake: file(
      relativePath: {
        eq: "עוגה-מצוירת-ונערה-הודית-מפוסלת-בבצק-סוכר-ושמש-מאחוריה.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    amsterdamCake: file(
      relativePath: {
        eq: "עוגת-אמסטרדם-מצוירת-וצלמת-מפוסלת-בבצק-סוכר-עם-אופניים-ומטריה.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    indianGirl: file(
      relativePath: { eq: "נערה-הודית-מפוסלת-בבצק-סוכר-עם-שמש-מאחוריה.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    giantCat: file(
      relativePath: { eq: "עוגה-מעוצבת-בבצק-סוכר-בצורת-חתול-ענק.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    kitchen: file(relativePath: { eq: "עוגת-שמח-במטבח-מפוסלת-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    playingKids: file(
      relativePath: { eq: "ילדים-במטבח-מפוסלים-בבצק-סוכר.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
  }
`
